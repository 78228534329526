.form-group {
    padding: 0 5px;
    @for $i from 1 through 12 {
        &.s-#{$i} {
            width: calc(100% / 12 * #{$i});
            flex: 0 0 calc(100% / 12 * #{$i});
            input,
            textarea,
            select {
                + label {
                    padding: inherit;
                    font-size: 13px;
                    line-height: 100%;
                }

                & {
                    & + label {
                        color: white;
                    }
                }
            }

            button.file-uploader {
                width: 100% !important;
                background-color: var(--ion-color-secondary) !important;
                + input[type="file"] {
                    display: none;
                }
            }

            @media (max-width: 991px) {
                width: 100%;
                flex: 0 0 100%;
            }
        }
    }

    .cidade {
        display: flex;
        gap: 10px;
        select {
            width: 100%;
            &[name="uf"] {
                width: 20%;
            }
        }

        + label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            letter-spacing: -0.025em;
            color: white;
            opacity: 1;
            position: absolute;
            top: -15px;
            left: 0;
            transition: 0.3s;
            pointer-events: none;
            padding: inherit;
            font-size: 13px;
            line-height: 100%;
        }
    }
}
