form {
    display: flex;
    margin-right: -5px;
    margin-left: -5px;
    flex-wrap: wrap;
    .check {
        margin-top: 15px;
        position: relative;
        input {
            position: absolute;
            left: 7px;
            top: 7px;
            width: 1px;
            height: 1px;
            opacity: 0.1;
            pointer-events: none;

            + label {
                cursor: pointer;
                text-align: left;
                display: grid;
                grid-template-columns: 25px 1fr;
                gap: 5px;
                text-align: justify;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: -0.025em;
                align-items: flex-start;
                color: #f5f5f5;
                span.checkbox {
                    width: 22px;
                    height: 22px;
                    border: 1px solid #d1d1d1;
                    background-color: white;
                    border-radius: 6px;
                    position: relative;

                    &::before {
                        content: "";
                        width: 14px;
                        height: 14px;
                        background-color: var(--ion-color-secondary);
                        position: absolute;
                        top: 3px;
                        left: 3px;
                        border-radius: 3px;
                        transition: 0.3s;
                        opacity: 0;
                    }
                }

                span {
                    a {
                        color: #f5f5f5;
                        font-weight: 700;
                        transition: 0.3s;
                        &:hover {
                            color: var(--ion-color-primary-tint);
                        }
                    }
                }
            }

            &:checked {
                + label {
                    span {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
.form-group {
    position: relative;
    margin-top: 20px;
    textarea {
        padding: 5px;
    }
    input,
    textarea {
        background: transparent;
        border: 0;
        border-bottom: 1px solid;
        box-sizing: border-box;
        border-radius: 0;
        height: 32px;
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: white;
        letter-spacing: -0.025em;
        padding-left: 5px;
        + label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            letter-spacing: -0.025em;
            color: white;
            opacity: 1;
            position: absolute;
            left: 0px;
            top: 10px;
            transition: 0.3s;
            pointer-events: none;
            padding: inherit;
            font-size: 13px;
            line-height: 100%;
        }

        &:focus,
        &:valid,
        &:active {
            + label {
                top: -15px;
                left: 0;
                opacity: 1;
                text-transform: lowercase;
                color: white;
            }
        }
    }

    textarea {
        height: 70px;
        resize: none;
    }

    select {
        width: 100%;
        height: 48px;
        border: 0;
        border-bottom: 1px solid #fff;
        background-color: transparent;
        border-radius: 0;
        color: white;
        padding-left: 10px;

        + label {
            padding-right: 5px;
            padding-left: 5px;
            top: -15px;
            left: 0;
            font-size: 13px;
            opacity: 1;
            text-transform: lowercase;
            position: absolute;
        }

        option {
            color: black;
        }
    }
}
