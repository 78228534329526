#footer {
    background: var(--ion-color-primary);
    padding: 50px 0;
    margin-top: 120px;
    @media (max-width: 991px) {
        margin-top: 50px;
    }
    .newsletter {
        @media (max-width: 991px) {
            display: none;
        }
        margin-top: -120px;
        .container {
            background-color: var(--ion-color-secondary);
            .content {
                padding: 50px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                h3 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 42px;
                    line-height: 100%;
                    letter-spacing: -0.02em;
                    color: #ffffff;
                }

                form {
                    .submit {
                        display: flex;
                        gap: 30px;
                        padding: 0 5px;
                        align-items: flex-start;
                        justify-content: space-between;

                        button {
                            background-color: var(--ion-color-primary);
                            min-width: 135px;
                            height: 46px;
                            color: white;
                            border: 0;
                            cursor: pointer;
                            margin-top: 15px;
                            font-weight: 600;
                            &:hover {
                                background-color: var(--ion-color-primary-shade);
                            }
                        }
                    }
                }
            }
        }
    }

    > .menu {
        margin-top: 100px;
        @media (max-width: 991px) {
            margin-top: 0;
        }
        > .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 991px) {
                flex-direction: column;
                gap: 25px;
            }
            > a {
                display: block;
                img {
                    display: block;
                    width: 150px;
                    height: 52px;
                }
            }

            .menu {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 30px;
                @media (max-width: 991px) {
                    display: none;
                }
                a {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    color: white;
                }
            }

            .socials {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 15px;
                @media (max-width: 991px) {
                    justify-content: center;
                }

                a {
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }

                &:empty {
                    display: none;
                }
            }
        }
    }

    > .endereco {
        p {
            padding: 0;
            text-align: center;
            color: white;
            border-top: 1px solid #fff;
            padding-top: 15px;
            font-size: 14px;
            margin-top: 25px;
        }
    }

    > .creditos {
        > .container {
            p {
                margin: 0;
                text-align: center;
                padding-top: 30px;
                margin-top: 0;
                border-top: 1px solid white;
                color: white;
                a {
                    font-weight: bold;
                    color: white;
                }
            }
        }
    }
}
