#header {
    background-color: var(--ion-color-primary);
    .container {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > a {
            display: block;
            margin-right: 90px;
            img {
                width: 150px;
                height: 52px;
            }
        }

        button.toggleMenu {
            width: 40px;
            height: 40px;
            display: none;
            position: relative;
            z-index: 99999;
            align-items: center;
            justify-content: center;
            border-radius: 0;
            border: 0;
            background-color: var(--ion-color-primary);
            transition: 0.3s;
            img {
                width: 24px;
                height: 24px;
            }

            &.active {
                border-radius: 50%;
                img {
                    width: 16px;
                    height: 16px;
                }
            }

            @media (max-width: 991px) {
                display: flex;
            }
        }

        .menu {
            width: 100%;
            height: 100%;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 25px;
            padding: 0 50px;

            a {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: var(--ion-color-primary);
            }

            @media (max-width: 991px) {
                position: fixed;
                z-index: 9999;
                top: 0;
                right: -400px;
                width: 300px;
                background-color: #cccccc;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10vh;
                transition: .3s;
                box-shadow: 0 0 50px rgba(0,0,0,.3);

                &.active {
                    right: 0;
                }
            }
        }

        .socials {
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            min-width: 380px;
            right: 0;
            position: absolute;
            top: 0;
            z-index: 2;
            background-color: var(--ion-color-secondary);
            @media (max-width: 991px) {
                display: none;
            }

            a {
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
